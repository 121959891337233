/* SearchResult.css */
.SearchResult {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .SearchResult:hover {
    background-color: #f1f1f1;
  }
  
  .SearchResult img {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    object-fit: cover;
  }
  
  .SearchResult-info {
    margin-left: 10px;
    text-align: left;
  }
  
  .SearchResult-info h4 {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  
  .SearchResult-info p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: #999;
  }
  