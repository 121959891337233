.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  z-index: 10;
  box-sizing: border-box;
}

.navbar a,
.navbar .logo {
  text-decoration: none;
}

.navbar .logo {
  font-weight: bold;
}

.navbar .back-to-search {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  color: #333; /* Add a color if desired */
  border-radius: 4px; /* Add rounded corners */
  transition: background-color 0.2s ease; /* Add a transition for the hover effect */
  position: sticky;
  right: 0;
}

.navbar .back-to-search:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Add a subtle background color change on hover */
}
