  .navbar {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    z-index: 10;
    box-sizing: border-box;
  }

  .logo-container,
  .back-to-search-container,
  .spotify-container {
    flex: 1;
  }

  .logo-container {
    display: flex;
    justify-content: flex-start;
  }

  .back-to-search-container {
    display: flex;
    justify-content: center;
  }

  .spotify-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .RecommendationPage {
    margin-top: 80px; /* Offset the page content below the navbar */
    padding: 2rem;
  }
  
  .original-song {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .original-song h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .original-song h3 {
    font-size: 1.2rem;
    color: #666;
  }
  
  .recommendations {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .action-buttons {
    display: flex;
    justify-content: flex-end; /* Change to flex-end to align the Create Playlist button to the right */
    align-items: center;
    margin-top: 2rem;
  }
  
  .load-more-btn {
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 12px 24px; /* Increase the padding to make the button bigger */
    font-size: 1.2rem; /* Increase the font size */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    margin-right: 1rem;
  }
  
  .load-more-btn:hover {
    background-color: #5a6268;
    transform: scale(1.1);
  }
  
  .create-playlist-btn {
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 6px 12px; /* Decrease the padding to make the button smaller */
    font-size: 1rem; /* Decrease the font size */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-left: 1rem;
  }

  .create-playlist-btn:hover {
    transform: scale(1.1);
  }
  
  .load-more-container {
    text-align: center;
    margin-bottom: 2rem;
  }

.spotify-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.spotify-text {
  margin-right: 5px;
}

.spotify-logo {
  width: 80px;
}
