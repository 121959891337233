/* SearchBar.css */
.SearchBar {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
  }
  
  .SearchBar input {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .SearchBar input:focus {
    border-color: #1db954;
  }
  
  .SearchResults {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  