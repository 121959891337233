/* App.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #84a9ac, #3f4d67);
  min-height: 100vh;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  z-index: 10;
  box-sizing: border-box;
}

.logo {
  font-size: 1.5rem;
  color: #ffffff;
}

.hero-section {
  margin-bottom: 2rem;
}

h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  color: #ffffff;
  font-size: 0.8rem;
}

.main-page {
  width: 100%;
  max-width: 1200px;
  padding: 8rem 1rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .main-page {
    padding: 6rem 1rem 1rem;
  }
}

.back-to-search {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
}