.favorite-trait-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .reasons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .reason-button {
    background-color: #1db954;
    color: #ffffff;
    padding: 8px 16px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .reason-button:hover {
    background-color: #1ed760;
  }

  .reason-button.selected {
    background-color: #118a3e; /* Darker shade of Spotify green */
    color: #ffffff;
}

  