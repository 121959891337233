.PlaceholderCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 2rem;
    width: 280px;
    box-sizing: border-box;
    flex: 0 1 280px;
  }
  
  .PlaceholderCard-image {
    width: 100%;
    height: 280px;
    border-radius: 8px;
    background-color: #f0f0f0;
    animation: shimmer 1.5s infinite;
  }
  
  .PlaceholderCard-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem; /* Added gap for spacing between elements */
    margin-top: 1rem;
    text-align: center;
  }
  
  .PlaceholderCard-info .PlaceholderCard-title,
  .PlaceholderCard-info .PlaceholderCard-artist,
  .PlaceholderCard-info .PlaceholderCard-reason {
    background-color: #f0f0f0;
    animation: shimmer 1.5s infinite;
  }
  
  .PlaceholderCard-info .PlaceholderCard-title {
    height: 20px;
    width: 80%;
  }
  
  .PlaceholderCard-info .PlaceholderCard-artist {
    height: 14px;
    width: 60%;
  }
  
  .PlaceholderCard-info .PlaceholderCard-reason {
    /* ... */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .PlaceholderCard-reason-text {
    font-size: 0.9rem;
    color: #777;
    text-align: center;
    position: relative;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100%;
    }
    100% {
      background-position: 200%;
    }
  }
  
  .PlaceholderCard-image,
  .PlaceholderCard-info .PlaceholderCard-title,
  .PlaceholderCard-info .PlaceholderCard-artist,
  .PlaceholderCard-info .PlaceholderCard-reason {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.9) 50%, /* Lighter shimmer color */
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
  }
  